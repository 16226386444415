<template>
  <div class="content">
    <orderList></orderList>
  </div>
</template>

<script>
import orderList  from "./orderListComponents.vue";
export default {
  components: {
    orderList,
  },
  data() {
    return {};
  },
  mounted() {},

  filters: {},
  methods: {},
};
</script>

<style lang="less" scoped>
.content {
  .search-top {
    height: 40px;
    float: right;
  }

  .search-bar {
    display: flex;
    justify-content: space-between;
  }
  .option-list-box {
    display: flex;
    span {
      cursor: pointer;
    }
  }
  .page-box {
    text-align: right;
  }
  .ml10r {
    margin-left: 10px;
  }
}
</style>