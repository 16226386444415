<template>
  <div class="content">
    <div style="clear: both"></div>
    <div class="search-bar">
      <el-form
        :inline="true"
        :model="listQuery"
        ref="departmentInfo"
        size="small"
      >
        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.orderId"
            placeholder="订单号"
            style="width: 200px"
            maxlength="64"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.userPhone"
            placeholder="用户手机"
            maxlength="11"
            style="width: 200px"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-date-picker
            v-model="searchCondition_date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions0"
            @change="searchConditionDateChage"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item style="margin-left: 20px">
          <el-select v-model="listQuery.state" placeholder="请选择">
            <el-option
              v-for="item in ordeStateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="searchBtn"
            >搜索</el-button
          >
          <el-button size="mini" @click="resetBtn">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      stripe
      :height="tableHeight"
      border
    >
      <el-table-column type="index" label="序号" width="65"> </el-table-column>
      <el-table-column
        prop="orderId"
        label="订单号"
        width="300"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="goodsName"
        label="商品名称"
        width="300"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="buyAmount"
        label="商品数量"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column label="订单渠道" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.fromChannel | fromChannelFilters }}</span>
        </template>
      </el-table-column>
      <el-table-column label="快递费（元）" width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.expressPrice / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付金额（元）" width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.exchangePrice / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="收货地址" show-overflow-tooltip width="420">
        <template slot-scope="scope">
          <span>{{
            scope.row.receiveAddressInfo | receiveAddressInfoFilter
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="userPhone"
        label="用户手机"
        width="110"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column label="快递信息" show-overflow-tooltip width="300">
        <template slot-scope="scope">
          <span>{{ scope.row.expressInfo }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="createTime" label="创建时间" width="170">
      </el-table-column>

      <el-table-column label="订单状态" width="80">
        <template slot-scope="scope">
          <span>{{ scope.row.state | orderStateFilters }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" v-slot="scope" width="80">
        <div class="option-list-box">
          <el-link
            v-if="scope.row.state == 2"
            :underline="false"
            type="primary"
            @click="goToDelive(scope.row)"
            >发货</el-link
          >
        </div>
      </el-table-column>
    </el-table>
    <div class="page-box mt20r">
      <el-pagination
        background
        @current-change="getTableList"
        :current-page.sync="listQuery.pageNum"
        :page-size="listQuery.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 编辑用户信息弹窗  -->
    <el-dialog
      title="填写运单信息"
      :visible.sync="editUserDialogVisible"
      width="400px"
    >
      <el-form
        :inline="true"
        :model="updateExpressInfo"
        ref="updateExpressInfo"
        size="small"
        :rules="updateExpressInfoRules"
      >
        <el-form-item style="margin-left: 20px" prop="expressInfo">
          <el-input
            v-model.trim="updateExpressInfo.expressInfo"
            placeholder="快递单信息"
            style="width: 300px"
            maxlength="100"
            clearable
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editUserDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmEditHandle"
          :disabled="updateingExpressInfo"
          :loading="updateingExpressInfo"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getOrderInfoBypage,
  updateExpressInfo,
} from "../../../api/order/index";

import { ordeStateOptions } from "../../../util/options";
export default {
  props: {
    state: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editUserDialogVisible: false,
      listQuery: {
        beginTime: "",//开始时间 yyyy-MM-DD
        endTime: "",//string结束时间 yyyy-MM-DD
        pageNum: 1,
        pageSize: 10,
        state: this.state, //订单状态  1-待支付 2-已经支付 3-已发货 4-待评价 5-已经完成 6-订单取消 7-支付失败
        userId: "", //用户,
        orderId: "", //订单号
        userPhone: "", //订单用户手机号
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      searchCondition_date: [], //搜索条件时间
      ordeStateOptions: ordeStateOptions,
      total: 0,
      tableData: [],
      updateingExpressInfo: false, //是否正在提交运单信息
      updateExpressInfo: {
        //修改物流信息
        orderInfoId: "", //订单id
        expressInfo: "", //快递信息
      },
      updateExpressInfoRules: {
        expressInfo: [
          {
            required: true,
            message: "运单信息必须填写",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getTableList();
    //  this.getDepartmentData();
  },

  filters: {
    receiveAddressInfoFilter(obj) {
      if (obj) {
        return obj.receiveAddress || "" + obj.houseNumber || "";
      } else {
        return "--";
      }
    },
  },
  methods: {
    searchConditionDateChage(val) {
      if (val) {
        this.listQuery.beginTime = val[0];
        this.listQuery.endTime = val[1];
      } else {
        this.listQuery.beginTime = "";
        this.listQuery.endTime = "";
      }
    },
    //确定编辑
    confirmEditHandle() {
      this.$refs["updateExpressInfo"].validate((volid) => {
        if (volid) {
          this.updateingExpressInfo = true; //禁用提交按钮
          console.log(this.updateExpressInfo);
          updateExpressInfo(this.updateExpressInfo)
            .then((res) => {
              if (res.code == 1) {
                this.getTableList();
                this.editUserDialogVisible = false;
              } else {
                this.$message.error(res.msg);
              }
              this.updateingExpressInfo = false; //释放禁用提交按钮
            })
            .catch((e) => {
              this.$message.error(e);
              this.updateingExpressInfo = false; //释放禁用提交按钮
            });
        }
      });
    },

    //列表查询
    getTableList() {
      // this.listQuery.pages = val;
      this.getTableData();
    },
    getTableData() {
      getOrderInfoBypage(this.listQuery).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //搜索按钮
    searchBtn() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    // 重置
    resetBtn() {

      this.searchCondition_date = "";
      this.listQuery.orderId = "";
      this.listQuery.userPhone = "";
      this.listQuery.beginTime = "";
      this.listQuery.endTime = "";
      this.getTableData();
    },
    //去发货事件处理
    goToDelive(row) {
      this.updateExpressInfo.expressInfo = ""; //清空上一次的运单内容
      this.updateExpressInfo.orderInfoId = row.orderId;
      this.editUserDialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .search-top {
    height: 40px;
    float: right;
  }

  .search-bar {
    display: flex;
    justify-content: space-between;
  }
  .option-list-box {
    display: flex;
    span {
      cursor: pointer;
    }
  }
  .page-box {
    text-align: right;
  }
  .ml10r {
    margin-left: 10px;
  }
}
</style>