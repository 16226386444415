//订单状态选项
export const ordeStateOptions = [{
        value: '',
        label: '全部'
    },
    {
        value: '1',
        label: '待支付'
    },
    {
        value: '2',
        label: '待发货'
    },
    {
        value: '3',
        label: '已发货'
    },
    {
        value: '4',
        label: '待评价'
    },
    {
        value: '5',
        label: '已经完成'
    },
    {
        value: '6',
        label: '订单取消'
    },
    {
        value: '7',
        label: '支付失败'
    }
]