import request from "@/base/util/request.js";

/**
 * 带条件获取全部订单信息
 * @param {*} data 
 * @returns 
 */
export const getOrderInfoBypage = (data) => {
        return request({
            url: "shoppingmallbackstage/orderInfo/getOrderInfoBypage",
            method: "post",
            data,
        })

    }
    /**
     * 修改订单快递信息
     * @param {*} data 
     * @returns 
     */
export const updateExpressInfo = (params) => {
    return request({
        url: "shoppingmallbackstage/orderInfo/updateExpressInfo",
        method: "get",
        params,
    })

}